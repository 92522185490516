import { StyledTab } from '@cfra-nextgen-frontend/cfra-institutional/src/features/home/components/StyledTab';
import { StyledTabs } from '@cfra-nextgen-frontend/cfra-institutional/src/features/home/components/StyledTabs';
import { Layout } from '@cfra-nextgen-frontend/shared';
import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { RenderMiddleText } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant5';
import { getRequestParamsPropsVariant1 } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import {
    ScreenerResearchCompanyData,
    ScreenerResearchData,
    UsageScreenerData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { CustomTabPanel } from '@cfra-nextgen-frontend/shared/src/components/TabPanel/CustomTabPanel';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ResearchDescriptionText } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/styledComponents';
import {
    TypographyStyle17,
    TypographyStyle2,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { useIsElementVisible } from '@cfra-nextgen-frontend/shared/src/hooks/isVisible';
import {
    ApiNames,
    EntityTypeLid,
    filterNullsAndDuplicates,
    fontFamilies,
    fontWeights,
    ProductLid,
    RequestTypes,
    UsageRequestTypes,
    UsageTypeLid,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { getUsageApiQueryKey } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { getCursorVariant1 } from '@cfra-nextgen-frontend/shared/src/utils/cursor';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { Box, createTheme, Stack, styled, ThemeProvider } from '@mui/material';
import { getReportCompanyProps, getReportProps } from 'analytics/utils';
import { useLinkGetter } from 'hooks/useLinkGetter';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { InstitutionalResearchReportTypeIds } from 'utils/enums';
import { Locations } from 'utils/preferences';
import { researchLinkGetterParams } from 'utils/researchLinkGetter';

const MaxItemsInList = 20;

const StyledTabsContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    top: '-3px',
    backgroundColor: '#FFF',
    padding: '18px 22px',
    paddingRight: '8px',
    borderRadius: '0 0 10px 10px',
}));

const SkeletonBox = (
    <Box sx={{ margin: '48px 0', width: '100%', textAlign: 'center' }}>
        <Layout.Skeleton height='10px' />
    </Box>
);

const NoResultsBox = (
    <Box sx={{ margin: '48px 0', width: '100%', textAlign: 'center' }}>
        <ResearchDescriptionText>No history available</ResearchDescriptionText>
    </Box>
);

type TabItem = {
    tabKey: number;
    label: string;
};

const tabItems: TabItem[] = [
    {
        tabKey: 1,
        label: 'Companies',
    },
    {
        tabKey: 2,
        label: 'Reports',
    },
];

export function RecentViews() {
    const [activeTab, _setActiveTab] = useState(1);
    const ref = useRef(null);
    const isRefVisible = useIsElementVisible(ref);

    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

    const setActiveTab = useCallback(
        (tabIndex: number) => {
            analyticsDataPickerRef.current?.registerAction({
                action: joinWithDelimiter({
                    values: ['click on tab', tabItems[tabIndex - 1].label],
                }),
            });
            _setActiveTab(tabIndex);
        },
        [_setActiveTab],
    );

    const tabPanelContainerStyles = useMemo(() => {
        const containerHeight = document.getElementById('daily-packet-container')?.clientHeight;

        return {
            height: `calc(${isRefVisible ? containerHeight : '400'}px - 144px)`,
            minHeight: '256px',
            paddingRight: '14px',
            overflowY: 'auto',
            ...scrollbarThemeV3,
        };
    }, [isRefVisible]);

    return (
        <ThemeProvider theme={createTheme()}>
            <AnalyticsDataContextProvider
                cfraDataLocal={{
                    actionData: {
                        tabName: tabItems[activeTab - 1].label,
                    },
                }}>
                <AnalyticsDataPicker ref={analyticsDataPickerRef} />
                <Stack bgcolor='#FFF' borderRadius='10px' ref={ref}>
                    <Box height='60px' p='20px' alignItems='center'>
                        <TypographyStyle2 fontWeight={fontWeights.Medium}>{Locations.MyRecentViews}</TypographyStyle2>
                    </Box>
                    <StyledTabs
                        value={activeTab}
                        variant='scrollable'
                        allowScrollButtonsMobile
                        onChange={(e: React.SyntheticEvent, v: number) => setActiveTab(v)}>
                        {tabItems.map((item: TabItem) => (
                            <StyledTab
                                key={item.tabKey}
                                label={item.label}
                                {...Layout.a11yProps(item.tabKey)}
                                value={item.tabKey}
                            />
                        ))}
                    </StyledTabs>
                    <StyledTabsContainer>
                        <Box sx={tabPanelContainerStyles}>
                            <CompanyTabPanel tabKey={1} activeTab={activeTab} />
                            <ReportTabPanel tabKey={2} activeTab={activeTab} />
                        </Box>
                    </StyledTabsContainer>
                </Stack>
            </AnalyticsDataContextProvider>
        </ThemeProvider>
    );
}

function CompanyTabPanel(props: { tabKey: number; activeTab: number }) {
    const { tabKey, activeTab } = props;
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const [viewedCompanyIds, setViewedCompanyIds] = useState<string[]>([]);

    const usageQuery = sendSingleRequest?.(
        {
            productLid: ProductLid.ForensicAccountingResearch,
            usageTypeLid: UsageTypeLid.PageView,
            entityTypeLid: [EntityTypeLid.Equity, EntityTypeLid.Company].join(','),
            fields: ['primary_entity_id']
        },
        {
            requestType: RequestTypes.GET,
            path: 'usage',
            queryKeyFirstElement: getUsageApiQueryKey(UsageRequestTypes.CompanyViewed),
            apiName: ApiNames.UserManagement,
        },
    ) as UseQueryResult<UsageScreenerData>;

    useEffect(() => {
        if (usageQuery?.data?.data) {
            const usageData = usageQuery.data.data.results?.data || [];
            const companyIds: string[] = filterNullsAndDuplicates(usageData.map((d: any) => d.primary_entity_id));
            setViewedCompanyIds([...companyIds.slice(0, MaxItemsInList)]);
        }
    }, [usageQuery?.data?.data]);

    const usageScreenerQuery = sendSingleRequest?.(
        {
            path: 'company/screener',
            size: MaxItemsInList,
            securityType: 'company',
            view: 'default',
            requestBody: {
                filters: {
                    values: {
                        'company_security.security_trading.cfra_security_trading_id': {
                            values: viewedCompanyIds,
                        },
                    },
                },
            },
            config: {
                enabled: viewedCompanyIds.length !== 0,
            },
        },
        {
            requestType: RequestTypes.POST,
            path: 'company/screener',
            queryKeyFirstElement: 'usageScreenerQuery',
            apiName: ApiNames.Research,
        },
    ) as UseQueryResult<ScreenerResearchCompanyData>;

    const { isLoading, dataList, noResults } = useMemo(() => {
        const data: any[] = [];
        let noResults = false;
        const isLoading = usageQuery.isLoading || usageScreenerQuery?.isLoading;
        if (isLoading) {
            return { isLoading, dataList: data, noResults };
        }
        if (viewedCompanyIds && !usageScreenerQuery?.isLoading && usageScreenerQuery?.data?.results?.company) {
            viewedCompanyIds.forEach((id: any, idx: number) => {
                const company = usageScreenerQuery?.data?.results?.company.find((item: any) => item.id === id);
                if (company) {
                    data.push(company);
                }
            });
        }
        noResults = !isLoading && (viewedCompanyIds.length === 0 || data.length === 0);

        return { isLoading, dataList: data, noResults };
    }, [
        usageQuery.isLoading,
        usageScreenerQuery?.data?.results?.company,
        usageScreenerQuery?.isLoading,
        viewedCompanyIds,
    ]);

    return (
        <CustomTabPanel index={tabKey} value={activeTab}>
            {isLoading && SkeletonBox}
            {noResults && NoResultsBox}
            {dataList.map((company: any, idx: number) => {
                return (
                    <AnalyticsDataContextProvider
                        key={idx}
                        cfraDataLocal={{
                            actionData: getReportCompanyProps({
                                researchReportSecurityItem: company?.company_security,
                            }),
                        }}>
                        <RenderMiddleTextWithAnalytics company={company} idx={idx} />
                    </AnalyticsDataContextProvider>
                );
            })}
        </CustomTabPanel>
    );
}

function RenderMiddleTextWithAnalytics({ company, idx }: { company: any; idx: number }) {
    const navigate = useNavigate();
    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);
    const companyText = '(' + company?.company_security.security_trading.ticker_symbol + 
                                    (company?.company_security?.security_trading?.exchange_lid?.exchange_code ? ('-' + company?.company_security?.security_trading?.exchange_lid?.exchange_code) : '') + ') ';
    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            <RenderMiddleText
                key={idx}
                prefix={
                    <TypographyStyle17
                        component='span'
                        sx={{
                            fontFamily: fontFamilies.GraphikMedium,
                            fontWeight: fontWeights.Medium,
                        }}>
                        {companyText}
                    </TypographyStyle17>
                }
                middleSubText={company?.company_security.company.company_name}
                displayMaxLength={0}
                onClick={() => {
                    analyticsDataPickerRef.current?.registerAction({
                        action: joinWithDelimiter({
                            values: [
                                'click on company',
                                `${companyText} ${company?.company_security.company.company_name}`,
                            ],
                        }),
                    });
                    setTimeout(
                        () => navigate(`/company-profile/security/${company?.company_security.security_trading.cfra_security_trading_id}`),
                        300,
                    );
                }}
                sx={{ padding: '8px 0', fontWeight: fontWeights.Regular, cursor: 'pointer' }}
            />
        </>
    );
}

function ReportTabPanel(props: { tabKey: number; activeTab: number }) {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const { tabKey, activeTab } = props;
    const [viewedReportIds, setViewedReportIds] = useState<string[]>([]);

    const usageQuery = sendSingleRequest?.(
        {
            productLid: ProductLid.ForensicAccountingResearch,
            usageTypeLid: UsageTypeLid.PageView,
            researchReportTypeId: InstitutionalResearchReportTypeIds.join(','),
            fields: ['research_report_opensearch_id']
        },
        {
            requestType: RequestTypes.GET,
            path: 'usage',
            queryKeyFirstElement: getUsageApiQueryKey(UsageRequestTypes.ReportViewed),
            apiName: ApiNames.UserManagement,
        },
    ) as UseQueryResult<UsageScreenerData>;

    useEffect(() => {
        if (usageQuery?.data?.data) {
            const usageData = usageQuery.data.data.results?.data || [];
            const reportIds: string[] = filterNullsAndDuplicates(
                usageData.map((d: any) => d.research_report_opensearch_id),
            );
            setViewedReportIds([...reportIds.slice(0, MaxItemsInList)]);
        }
    }, [usageQuery.data, usageQuery.data?.data]);

    const usageScreenerQuery = sendSingleRequest?.(
        {
            path: 'research/screener',
            size: MaxItemsInList,
            securityType: 'research',
            view: 'pdf',
            requestBody: {
                filters: {
                    values: {
                        'insights.research_report._id': {
                            values: viewedReportIds,
                        },
                    },
                },
            },
            config: {
                enabled: viewedReportIds.length !== 0,
            },
        },
        {
            requestType: RequestTypes.POST,
            path: 'research/screener',
            queryKeyFirstElement: 'usageScreenerQuery',
            apiName: ApiNames.Research,
        },
    ) as UseQueryResult<ScreenerResearchData>;

    const { isLoading, dataList, noResults } = useMemo(() => {
        const data: any[] = [];
        let noResults = false;
        const isLoading = usageQuery.isLoading || usageScreenerQuery?.isLoading;
        if (isLoading) {
            return { isLoading, dataList: data, noResults };
        }

        if (viewedReportIds && !usageScreenerQuery?.isLoading && usageScreenerQuery?.data?.results?.research) {
            viewedReportIds.forEach((id: any, idx: number) => {
                const research = usageScreenerQuery?.data?.results?.research.find((item: any) => item.id === id);
                if (research) {
                    data.push(research);
                }
            });
        }

        noResults = !isLoading && (viewedReportIds.length === 0 || data.length === 0);

        return { isLoading, dataList: data, noResults };
    }, [
        usageQuery.isLoading,
        usageScreenerQuery?.data?.results?.research,
        usageScreenerQuery?.isLoading,
        viewedReportIds,
    ]);

    return (
        <CustomTabPanel index={tabKey} value={activeTab}>
            {isLoading && SkeletonBox}
            {noResults && NoResultsBox}
            {dataList.map((research: any, idx: number) => {
                return (
                    <AnalyticsDataContextProvider
                        key={idx}
                        cfraDataLocal={{
                            actionData: getReportProps({ research: [research] }),
                        }}>
                        <ReportsLinkTitle key={idx} research={research} />
                    </AnalyticsDataContextProvider>
                );
            })}
        </CustomTabPanel>
    );
}

function ReportsLinkTitle({ research }: { research: any }) {
    const linkGetterParams = {
        ...researchLinkGetterParams[0],
        usageLoggerProps: { ...researchLinkGetterParams[0].usageLoggerProps, invalidateUsage: false },
    };
    const { setRequestParamsProps, isLoading, isError } = useLinkGetter(linkGetterParams, research.id);

    const handleLinkClick = useCallback(() => {
        setRequestParamsProps(getRequestParamsPropsVariant1(research.id));
    }, [research.id, setRequestParamsProps]);

    const cursor = useMemo(() => getCursorVariant1(isLoading, isError), [isLoading, isError]);

    return (
        <RenderMiddleText
            middleSubText={research?.research_report?.title}
            displayMaxLength={0}
            sx={{ padding: '8px 0', fontWeight: fontWeights.Regular, cursor: cursor }}
            onClick={handleLinkClick}
        />
    );
}
