import { getLockModal } from 'components/LockComponent/getLockModalContent';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { TableSkeleton } from '@cfra-nextgen-frontend/shared/src/utils/skeleton';
import { TypographyStyle6 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { StyledAvatar } from '@cfra-nextgen-frontend/shared/src/components/Avatar/Avatar';
import checkRounded from '@cfra-nextgen-frontend/shared/src/assets/icons/check-rounded.svg';
import { RoundedTextButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/RoundedTextButton';
import { roundedTextButtonThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { useState } from 'react';
import { Grid } from '@mui/material';

const styles = {
    headers: {
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '13.5px',
        fontWeight: 500,
        lineHeight: '20px',
        color: '#5A5A5A',
    },
    subHeader: {
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '15px',
        color: '#002B5A',
        lineHeight: '22px',
        fontWeight: 500,
    },
    text: {
        fontFamily: fontFamilies.GraphikRegular,
        lineHeight: '22px',
        fontSize: '15px',
        color: '#002B5A',
    },
};

export default function BiggestConcernNotEntitledContent() {
    const [modalOpen, setModalOpen] = useState(false);

    const LockModal = getLockModal({
        content: 'Biggest Concern Access',
    });

    return (
        <Grid container>
            <Grid
                item
                container
                xs={12}
                sx={{
                    borderBottom: '1px solid #AAAAAA',
                    paddingBottom: '10px',
                    marginBottom: '10px',
                    marginRight: '10px',
                }}>
                <Grid item xs={3} sm={1}>
                    <span style={styles.headers}>Ticker</span>
                </Grid>
                <Grid item xs={5.5} sm={4.5}>
                    <span style={styles.headers}>Company</span>
                </Grid>
                <Grid item xs={0} sm={3} sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <span style={styles.headers}>Concern Status</span>
                </Grid>
                <Grid item xs={0} sm={1.5} sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <span style={styles.headers}>Action</span>
                </Grid>
                <Grid item xs={3.5} sm={2} textAlign={'right'}>
                    <span style={styles.headers}>Date Added</span>
                </Grid>
            </Grid>
            <Grid sx={{ position: 'relative', width: '100%' }}>
                <TableSkeleton
                    sx={{ position: 'absolute' }}
                    columns={[{ flex: 2.5 }, { flex: 6.5 }, { flex: 2.8 }]}
                    numberOfRows={6}
                />
            </Grid>
            <Grid sx={{ padding: '20px', backdropFilter: 'blur(5px)',  }} flexDirection={'column'} rowSpacing={5}>
                <Grid item xs={12} marginBottom={'10px'}>
                    <TypographyStyle6 sx={{ fontSize: '32px', lineHeight: '38px', fontWeight: 600 }}>
                        Access our highest conviction ideas
                    </TypographyStyle6>
                </Grid>
                <Grid item xs={12} display={'flex'} flexDirection={'row'} marginBottom={'10px'}>
                    <span style={styles.subHeader}>With the Risk Monitor, you have access to:</span>
                </Grid>
                <Grid item xs={12} display={'flex'} flexDirection={'row'} marginBottom={'10px'}>
                    <Grid item sx={{ width: '52px' }} display={'flex'} alignItems={'center'}>
                        <StyledAvatar src={checkRounded} sx={{ height: '32px', width: '32px' }} />
                    </Grid>
                    <Grid item sx={{ width: 'calc(100% - 52px)' }}>
                        <span style={styles.subHeader}>Biggest Concerns: </span>
                        <span style={styles.text}>
                            Our actively curated portfolio of global companies representing our highest conviction ideas
                            that show substantial risk of deteriorating earnings quality or fraud.
                        </span>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <RoundedTextButton
                        sx={{
                            fontSize: '13px',
                            borderRadius: '10px',
                            padding: '6px 25px',
                            '&:hover': { cursor: 'cursor' },
                        }}
                        theme={roundedTextButtonThemeV3}
                        buttonText={'Learn more'}
                        onClickCallback={() => setModalOpen(true)}
                    />
                </Grid>
            </Grid>
            <LockModal
                modalOpen={modalOpen}
                handleCloseModal={() => setModalOpen(false)}
                handleClose={() => setModalOpen(false)}
            />
        </Grid>
    );
}
