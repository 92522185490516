import { Layout } from '@cfra-nextgen-frontend/shared';
import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGridCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import {
    AgGridThemes,
    getColumnDefsEqualComparator,
    keepNoLeftPaddingOnMove,
} from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import { noResultText } from '@cfra-nextgen-frontend/shared/src/components/Card/BannerCard';
import { CardHeaderVariant1 } from '@cfra-nextgen-frontend/shared/src/components/CardHeaders/CardHeaderVariant1';
import { InfiniteOptions } from '@cfra-nextgen-frontend/shared/src/components/InfiniteScroll/InfiniteOptions';
import {
    ItemVariant3,
    ItemVariant3Container,
} from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant3';
import { GridViewsPanel, Views } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFButtonsPannel/ViewsPanel';
import { getRequestParamsPropsVariant1 } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { DataItem, ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { getRowID } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/ssr';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ResearchDescriptionText } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/styledComponents';
import {
    UserPreferences as UserPreferencesComponent,
    UserPreferencesRef,
} from '@cfra-nextgen-frontend/shared/src/components/UserPreferences/UserPreferences';
import { getUserColumnDefs } from '@cfra-nextgen-frontend/shared/src/components/UserPreferences/utils/agGrid';
import { GetRequestParamsProps } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { useSsrDataSource } from '@cfra-nextgen-frontend/shared/src/hooks/useSsrDataSource';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import {
    AgGridPreferencesEachElement,
    IdsAndSelectionsPreferencesActions,
    IdsAndSelectionsPreferencesEachElement,
    IdsAndSelectionsTypeExtension,
    PreferenceType,
} from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { ApiNames, RequestTypes, ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { Box, createTheme, Grid, Stack, SxProps, ThemeProvider } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { getReportOpenAnalyticsCallback, getReportProps } from 'analytics/utils';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import { BadgeBiggestConcerns, BadgeHazardList } from 'components/BadgeBiggestConcerns';
import { WatchlistCompanyIds } from 'components/Watchlist/ManageWatchlist';
import { availableReports, getResearchAggregates } from 'features/companyProfile/api/company';
import { FilterComponent, getMenuItems } from 'features/companyProfile/components/CompanyHeadlines';
import { MenuItemState } from 'features/companyProfile/types/company';
import moment from 'moment';
import { ComponentProps, Dispatch, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { sortPrimaryFirst } from 'utils/arrays';
import { defaultAutosizePadding, defaultTooltipShowDelay } from 'utils/lookAndFeel';
import { Locations, UserPreferences, UserPreferencesSelectors, useUserPreferencesProps } from 'utils/preferences';
import { researchLinkGetterParams } from 'utils/researchLinkGetter';
import { getScreenerInfiniteOptionsProps, getScreenerInfiniteRequestParamsConfig } from './InfiniteOptions/screener';
import { getAnalyticsActionDataForLink } from 'components/AgGrid/utils';

const ALLOWED_RESEARCH_IN_COMPANY_REPORTS = [
    ResearchTypeId.LegalReport,
    ResearchTypeId.LegalAlert,
    ResearchTypeId.FrsCompanyReport,
    ResearchTypeId.FrsNotebooks,
    ResearchTypeId.FrsEarningsAlert,
    ResearchTypeId.LegalPipeline,
    ResearchTypeId.LegalPipelineSpecialInterestList,
];
const size = 25;
const scrollThresholdPx = Math.round((48 * size) / 2); // take 50% of fetching size, assume each item height is 48px

const requestCommonParams: SearchByParams = {
    securityType: 'research',
    view: 'company_reports',
    orderBy: 'research_report.publish_timestamp',
    sortDirection: 'desc',
};

const screenerInfiniteRequestParamsConfig = {
    queryKeyFirstElement: 'companyReportsScreenerQuery',
    ...getScreenerInfiniteRequestParamsConfig<'research'>(size, 'research'),
};

export const CompanyReportsContainerStyles: SxProps = {
    backgroundColor: '#FFFF',
    borderRadius: '10px',
    padding: '8px 10px 15px 10px',
    height: '100%',
    width: '100%',
};

export const gridLoadingContainerStyles: SxProps = { margin: '48px 0', width: '100%', textAlign: 'center' };

const cfraSecurityTradingIdField =
    'insights.research_report_security__trading.research_report_security.security_trading.cfra_security_trading_id';
const cfraCompanyIdField =
    'insights.research_report_security__company.research_report_security.company.cfra_company_id';

type CompanyReportsProps = {
    selectedView: Views;
    onViewChange: (v: Views) => void;
    researchTypeIds?: Array<number>;
    setResearchTypeIds: Dispatch<Array<number>>;
    watchlistCompanyIds: WatchlistCompanyIds;
};

const preferencesConfigurationCommon = {
    useUserPreferencesProps,
    selectorConfiguration: {
        selector: UserPreferencesSelectors[UserPreferences.HomePageCompanyReportsCommon],
    },
};

export function CompanyReports(props: CompanyReportsProps) {
    const { researchTypeIds, setResearchTypeIds, watchlistCompanyIds } = props;
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    const userPreferencesRef = useRef<UserPreferencesRef>(null);

    const { getUserPreferences } = useUserPreferences(preferencesConfigurationCommon?.useUserPreferencesProps);

    const calculatedView: Views = useMemo(() => {
        const defaultValue = props.selectedView;

        const userViewPreferences = getUserPreferences?.<IdsAndSelectionsPreferencesEachElement>({
            preferenceType: PreferenceType.IdsAndSelectionsPreferences,
            selector: preferencesConfigurationCommon?.selectorConfiguration.selector,
        });

        if (!userViewPreferences?.view) {
            return defaultValue;
        }

        return userViewPreferences.view as Views;
    }, [props.selectedView, getUserPreferences]);

    const [selectedView, _setSelectedView] = useState<Views>(calculatedView);
    const [reports, setReports] = useState<Array<MenuItemState>>([]);
    const [selectedReportIds, setSelectedReportIds] = useState<Array<number>>([]);

    const setSelectedView = useCallback((view: Views) => {
        _setSelectedView(view);

        userPreferencesRef.current?.setUserPreferences?.<
            keyof typeof IdsAndSelectionsPreferencesActions,
            IdsAndSelectionsTypeExtension
        >(PreferenceType.IdsAndSelectionsPreferences, {
            ...preferencesConfigurationCommon.selectorConfiguration.selector,
            action: IdsAndSelectionsPreferencesActions.SetSelectedView,
            view,
        });
    }, []);

    useEffect(() => {
        if (selectedView !== props.selectedView) {
            props.onViewChange(selectedView);
        }
    }, [props, props.selectedView, selectedView]);

    const watchlistFilter = useMemo(() => {
        const filter = [];
        const { cfraCompanyId, cfraSecurityTradingId } = watchlistCompanyIds;

        if (cfraCompanyId && cfraSecurityTradingId) {
            if (cfraSecurityTradingId.length > 0) {
                filter.push({ [cfraSecurityTradingIdField]: { values: cfraSecurityTradingId } });
            }

            if (cfraCompanyId.length > 0) {
                filter.push({ [cfraCompanyIdField]: { values: cfraCompanyId } });
            }

            if (cfraSecurityTradingId.length > 0 || cfraCompanyId.length > 0) {
                return { $or: filter };
            }
            return {};
        }
    }, [watchlistCompanyIds]);

    const companyReportsQryProps: SearchByParams = useMemo(
        () => ({
            ...requestCommonParams,
            requestBody: getCompanyReportsReqBody(selectedReportIds, watchlistFilter),
            config: { enabled: !!selectedReportIds?.length },
        }),
        [watchlistFilter, selectedReportIds],
    );

    const companyReportsQry = sendSingleRequest?.(
        {
            size: size,
            ...companyReportsQryProps,
        },
        {
            path: 'research/screener',
            ...screenerInfiniteRequestParamsConfig,
        },
    ) as UseQueryResult<ScreenerResearchData>;

    const researchReportTypesQuery = sendSingleRequest?.(
        {
            path: 'research',
            securityType: 'research',
            includeData: true,
            includeMetadata: false,
            filters: 'insights.research_report.research_type_name',
            requestBody: getCompanyReportsReqBody(ALLOWED_RESEARCH_IN_COMPANY_REPORTS, watchlistFilter),
            config: {},
        },
        {
            apiName: ApiNames.Research,
            requestType: RequestTypes.POST,
            path: 'research/screener-filters',
            queryKeyFirstElement: 'getCompanyReportsResearchTypes',
        },
    );

    useEffect(() => {
        if (researchReportTypesQuery?.isSuccess) {
            const allReports = getResearchAggregates(researchReportTypesQuery?.data);
            const _research_type_ids = researchTypeIds || [];
            const _selectedReportTypes = availableReports
                .filter((report) => _research_type_ids.includes(report.key))
                .map((report) => report.value);

            setReports(
                allReports.map((r) => ({
                    isChecked: _selectedReportTypes.includes(r.value),
                    key: r.key,
                    name: r.value,
                    count: r.count,
                })),
            );

            if (_research_type_ids.length > 0) {
                setSelectedReportIds(_research_type_ids);
            } else {
                setSelectedReportIds(ALLOWED_RESEARCH_IN_COMPANY_REPORTS);
            }
        }
    }, [researchReportTypesQuery?.data, researchReportTypesQuery?.isSuccess, researchTypeIds]);

    const handleOnChange = useCallback(
        (_e: any, _report: { name: string }) => {
            const newReportState = reports.map((report) => {
                if (report.name === _report.name) {
                    report.isChecked = !report.isChecked;
                }
                return report;
            });
            setReports(newReportState);

            let reportIds: number[] = [];
            const selectedReportNames = newReportState
                .filter((report) => report.isChecked)
                .map((report) => report.name);

            if (selectedReportNames.length) {
                reportIds = availableReports
                    .filter((report) => selectedReportNames.includes(report.value))
                    .map((report) => report.key);
            }

            if (reportIds.length) {
                setSelectedReportIds(reportIds);
                setResearchTypeIds(reportIds);
            } else {
                setSelectedReportIds(ALLOWED_RESEARCH_IN_COMPANY_REPORTS);
                setResearchTypeIds([]);
            }

            return reports.find((report) => report.name === _report.name)?.isChecked ? 'checked' : 'unchecked';
        },
        [reports, setResearchTypeIds],
    );

    const searchResultsHeader = useMemo(() => {
        return (
            <CardHeaderVariant1
                title={Locations.CompanyReports}
                containerStyles={{ paddingX: '10px' }}
                slot1={
                    <FilterComponent
                        menuItems={getMenuItems(reports, handleOnChange)}
                        checkedCount={reports.filter((report) => report.isChecked).length}
                    />
                }
                slot3={<GridViewsPanel onClickCallback={setSelectedView} defaultViewSelected={selectedView} />}
            />
        );
    }, [handleOnChange, reports, selectedView, setSelectedView]);

    return (
        <ThemeProvider theme={createTheme()}>
            <AnalyticsDataContextProvider
                cfraDataLocal={{
                    actionData: {
                        list1: reports
                            .filter((item) => item.isChecked)
                            .map((item) =>
                                joinWithDelimiter({
                                    values: ['Document Type', item.name, String(item.key)],
                                }),
                            ),
                    },
                }}>
                <Stack sx={CompanyReportsContainerStyles}>
                    <Grid container>{searchResultsHeader}</Grid>

                    {!companyReportsQry?.isFetched && (
                        <Box sx={gridLoadingContainerStyles}>
                            <Layout.Skeleton height='10px' />
                        </Box>
                    )}

                    {companyReportsQry?.isFetched &&
                        ((companyReportsQry?.data?.results?.research || []).length === 0 ? (
                            <Box sx={gridLoadingContainerStyles}>
                                <ResearchDescriptionText>{noResultText}</ResearchDescriptionText>
                            </Box>
                        ) : (
                            <AnalyticsDataContextProvider
                                cfraDataLocal={{
                                    actionData: {
                                        selectedView,
                                    },
                                }}>
                                {selectedView === Views.GridView && (
                                    <CompanyReportsGridView
                                        selectedReportIds={selectedReportIds}
                                        watchlistFilter={watchlistFilter}
                                    />
                                )}
                                {selectedView === Views.TableView && (
                                    <CompanyReportsTableView
                                        companyReportsQryProps={companyReportsQryProps}
                                        companyReportsQry={companyReportsQry}
                                    />
                                )}
                            </AnalyticsDataContextProvider>
                        ))}
                    <UserPreferencesComponent
                        ref={userPreferencesRef}
                        useUserPreferencesProps={preferencesConfigurationCommon.useUserPreferencesProps}
                    />
                </Stack>
            </AnalyticsDataContextProvider>
        </ThemeProvider>
    );
}

type CompanyReportsView = {
    selectedReportIds: Array<number>;
    watchlistFilter: any;
};

const gridTheme = [AgGridThemes.CpGridTheme, 'ag-panel-top-position-fixed'];

const preferencesConfigurationTableView = {
    useUserPreferencesProps,
    selectorConfiguration: {
        selector: UserPreferencesSelectors[UserPreferences.HomePageCompanyReportsTableView],
    },
};

function CompanyReportsTableView(props: { companyReportsQry: any; companyReportsQryProps: any }) {
    const { companyReportsQry, companyReportsQryProps } = props;
    const gridRef = useRef<AgGridReact>(null);

    const { getRows } = useSsrDataSource({
        pageSize: size,
        resultsKey: 'research',
        reqParams: companyReportsQryProps,
        metadataFields: companyReportsQry?.data?._metadata.fields || [],
        reqConfig: { path: 'research/screener', ...screenerInfiniteRequestParamsConfig },
    });

    const { getUserPreferences, setUserPreferences } = useUserPreferences(
        preferencesConfigurationTableView?.useUserPreferencesProps,
    );

    const { minWidths, customFlexibleColumns, columnDefs }: ReturnType<typeof extractFromScreenerData> = useMemo(() => {
        if (!companyReportsQry.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }

        const result = extractFromScreenerData({
            screenerData: companyReportsQry.data,
            cardName: 'Company Research',
            outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
            keepNoLeftPadding: true,
        });

        const userPreferences = getUserPreferences?.<AgGridPreferencesEachElement>({
            preferenceType: PreferenceType.AgGridPreferences,
            selector: preferencesConfigurationTableView.selectorConfiguration.selector,
        });

        if (!userPreferences) {
            return result;
        }

        return {
            ...result,
            columnDefs: getUserColumnDefs({
                initialColumnDefs: result.columnDefs,
                userPreferences,
            }),
        };
    }, [companyReportsQry?.data, getUserPreferences]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? defaultMinWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const columnDefsEqualComparator = useMemo(() => {
        return getColumnDefsEqualComparator();
    }, []);

    const TableView = useMemo(() => {
        return (
            <AgGridCard
                ref={gridRef}
                useSSRMode={true}
                getRowID={getRowID}
                SSRrowsToFetch={size}
                embedFullWidthRows={true}
                columnDefs={columnDefs}
                gridTheme={gridTheme}
                unlimitedCalculatedHeight
                SSRDataSource={{
                    getRows: (params) => getRows(params, companyReportsQryProps),
                }}
                maxNumberOfRowsToDisplay={10}
                showDefaultExportButton={false}
                suppressRowClickSelection={true}
                customFlexibleColumns={customFlexibleColumns}
                containerStyles={{ marginTop: 0, paddingLeft: '10px' }}
                getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                useDragScroll
                onColumnMovedGetter={keepNoLeftPaddingOnMove}
                onColumnVisibleGetter={keepNoLeftPaddingOnMove}
                autoSizePadding={defaultAutosizePadding}
                tooltipShowDelay={defaultTooltipShowDelay}
                autosizeColumnsConfig={{
                    skipHeader: false,
                    skipHasPinnedColumnsCheck: true,
                }}
                suppressHeaderMenuButton={false}
                checkColumnDefsEqual={columnDefsEqualComparator}
                preferencesConfiguration={preferencesConfigurationTableView}
                enableSavingUserSortModel
                enableSavingUserColumnsOrder
                enableSavingUserColumnsVisibility
                enableSavingUserColumnsWidths
                useDisableColumnFlexOnResize
                useColumnWidthsFromColumnDefs
                setUserPreferencesOnReset={setUserPreferences}
            />
        );
    }, [
        columnDefs,
        customFlexibleColumns,
        getResizableMinWidthForColumn,
        getRows,
        companyReportsQryProps,
        columnDefsEqualComparator,
        setUserPreferences,
    ]);

    return TableView;
}

function CompanyReportsGridView(props: CompanyReportsView) {
    const { selectedReportIds, watchlistFilter = {} } = props;
    const navigate = useNavigate();
    const outerContainerRef = useRef<HTMLDivElement | null>(null);

    const onPillClickHandler = useCallback(
        (url: string) => {
            navigate(url);
        },
        [navigate],
    );

    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

    const GridView = useMemo(() => {
        // Omit excludes the children prop from the type
        return (
            <>
                <AnalyticsDataPicker ref={analyticsDataPickerRef} />
                <InfiniteOptions<
                    ScreenerResearchData,
                    DataItem,
                    Omit<ComponentProps<typeof ItemVariant3Container>, 'children'>
                >
                    key='gridView'
                    OptionsContainer={ItemVariant3Container}
                    loadingContainerStyles={{ paddingBottom: '20px' }}
                    noResultsFoundBoxSxProps={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: '48px',
                    }}
                    optionsContainerProps={{
                        containerStyles: {
                            paddingLeft: '5px',
                            maxHeight: '510px',
                            overflowY: 'scroll',
                            justifyContent: 'flex-start',
                            ...scrollbarThemeV3,
                        },
                        otherContainerProps: {
                            gap: 1.7,
                        },
                    }}
                    infiniteRequestParams={[
                        {
                            path: 'research/screener',
                            size: size,
                            ...requestCommonParams,
                            requestBody: getCompanyReportsReqBody(selectedReportIds, watchlistFilter),
                            config: {
                                enabled: !!selectedReportIds?.length,
                            },
                        },
                        screenerInfiniteRequestParamsConfig,
                    ]}
                    RowComponent={({ rowData }) => {
                        const orderedAuthors = sortPrimaryFirst(rowData?.research_report_author);
                        const orderedCompanies = sortPrimaryFirst(rowData?.research_report_security__trading);

                        return (
                            <AnalyticsDataContextProvider
                                cfraDataLocal={{
                                    actionData: getReportProps({ research: [rowData] }),
                                }}>
                                <ItemVariant3<ScreenerResearchData>
                                    containerStyles={(theme: any) => ({
                                        borderRadius: '10px',
                                        padding: '18.75px 24px 24px',
                                        [theme.breakpoints.down(600)]: {
                                            width: '100%',
                                            maxWidth: '100%',
                                            flexBasis: '100%',
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            height: '255px',
                                        },
                                    })}
                                    rootItemProps={{
                                        lg: 3.8,
                                        xs: 5.8,
                                    }}
                                    middleContainerStyle={(theme: any) => ({
                                        [theme.breakpoints.up('lg')]: {
                                            maxHeight: '135px',
                                            overflow: 'hidden',
                                            paddingBottom: '5px',
                                        },
                                    })}
                                    bottomPillItemsThreshold={3}
                                    maxMiddleSubTextLength={125}
                                    useLinkGetterParams={[
                                        {
                                            ...researchLinkGetterParams[0],
                                            ...getReportOpenAnalyticsCallback({
                                                reportId: rowData?.id,
                                                analyticsDataPickerRef,
                                            }),
                                        },
                                    ]}
                                    topText={rowData?.research_report?.research_type_name}
                                    topSubText={moment(rowData?.research_report.publish_timestamp).format(
                                        'MMMM D, YYYY',
                                    )}
                                    middleTitle={rowData?.research_report?.title}
                                    middleSubText={rowData?.research_report?.teaser || ''}
                                    bottomTextItems={orderedAuthors.map(
                                        (author: any) => author?.platinum?.analyst?.pen_name,
                                    )}
                                    bottomPillItems={orderedCompanies?.map((company: any) => ({
                                        content: company?.research_report_security?.security_trading?.ticker_symbol,
                                        tooltipContent: company?.research_report_security?.company.company_name,
                                        onClick: () => {
                                            const analyticsActionData = getAnalyticsActionDataForLink({
                                                resultValue: [rowData],
                                                linkData: company?.research_report_security,
                                                analyticsActionTrackingMode: ['research', 'ticker'],
                                            });

                                            if (analyticsActionData) {
                                                analyticsDataPickerRef.current?.registerAction({
                                                    ...analyticsActionData,
                                                    action: joinWithDelimiter({
                                                        values: [
                                                            analyticsActionData.action || '',
                                                            company?.research_report_security?.security_trading
                                                                ?.ticker_symbol,
                                                        ],
                                                    }),
                                                });
                                            }

                                            setTimeout(
                                                () =>
                                                    onPillClickHandler(
                                                        `/company-profile/security/${company.research_report_security?.security_trading?.cfra_security_trading_id}`,
                                                    ),
                                                1000,
                                            );
                                        },
                                    }))}
                                    handleOuterSetRequestParamsProps={(
                                        setRequestParamsProps: (props: GetRequestParamsProps) => void,
                                    ) => {
                                        setRequestParamsProps(getRequestParamsPropsVariant1(rowData?.id));
                                    }}
                                    topTextRightSlot={
                                        (rowData?.frs_biggest_concerns_list?.is_biggest_concern && (
                                            <BadgeBiggestConcerns />
                                        )) ||
                                        (rowData?.frs_hazard_list?.is_biggest_concern && <BadgeHazardList />)
                                    }
                                />
                            </AnalyticsDataContextProvider>
                        );
                    }}
                    {...getScreenerInfiniteOptionsProps<'research'>('research')}
                    scrollThresholdPx={scrollThresholdPx}
                    outerContainerRef={outerContainerRef}
                />
            </>
        );
    }, [onPillClickHandler, selectedReportIds, watchlistFilter]);

    return GridView;
}

function getCompanyReportsReqBody(researchReportTypes: number[], watchlistFilter: any = {}) {
    return {
        filters: {
            values: {
                $and: [
                    {
                        'insights.research_report.research_type_id': {
                            values: researchReportTypes,
                        },
                    },
                    ...(Object.keys(watchlistFilter).length > 0 ? [watchlistFilter] : []),
                ],
            },
        },
        requested_filters: ['insights.research_report.research_type_name'],
    };
}

export function ReportsLoadingContainerCard() {
    return (
        <ThemeProvider theme={createTheme()}>
            <Stack sx={CompanyReportsContainerStyles}>
                <Box sx={gridLoadingContainerStyles}>
                    <Layout.Skeleton height='10px' />
                </Box>
            </Stack>
        </ThemeProvider>
    );
}
