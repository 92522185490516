import { ETFInfoButton } from '@cfra-nextgen-frontend/shared';
import { tooltipThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import {
    TypographyStyle35,
    TypographyStyle36,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Box, SxProps } from '@mui/material';
import { BadgeBiggestConcerns, BadgeHazardList } from 'components/BadgeBiggestConcerns';
import {
    HIDE_HAZARD_LIST
} from '@cfra-nextgen-frontend/shared/src/config';


export function getInformationPopupContent({
    textSx,
    hazardListPrefix,
}: {
    textSx?: SxProps;
    hazardListPrefix?: string;
}) {
    return (
        <>
            <Box sx={{ paddingBottom: '8px', width: '100%' }}>
                <TypographyStyle36 display='inline' sx={textSx}>
                    Biggest Concerns
                </TypographyStyle36>
                <BadgeBiggestConcerns tooltipTitle='' containerSx={{ display: 'inline-block', padding: '0px 8px' }} />
                <TypographyStyle35 display='inline' sx={textSx}>
                    Portfolio of our highest conviction companies with substantial risk of deteriorating earnings
                    quality or fraud.
                </TypographyStyle35>
            </Box>
            {!HIDE_HAZARD_LIST && <Box sx={{ width: '100%' }}>
                <TypographyStyle36 display='inline' sx={textSx}>
                    {hazardListPrefix}Hazard List
                </TypographyStyle36>
                <BadgeHazardList tooltipTitle='' containerSx={{ display: 'inline-block', padding: '0px 8px' }} />
                <TypographyStyle35 display='inline' sx={textSx}>
                    Companies with significant accounting or governance risks, warranting extreme caution for investors,
                    especially in long positions.
                </TypographyStyle35>
            </Box>}
        </>
    );
}

export function InformationIcon() {
    return (
        <ETFInfoButton tooltipTheme={tooltipThemeV3} tooltipText={getInformationPopupContent({})} buttonFontSize={20} />
    );
}
