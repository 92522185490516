import { iconConfigurationVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Icon/iconConfigurations';
import { IconWithLoading } from '@cfra-nextgen-frontend/shared/src/components/Icon/IconWithLoading';
import { LockIconComponent } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/LockIconComponent';
import { LockModal } from 'components/LockComponent/LockModal';
import {
    getRequestParamsPropsVariant1,
    getRequestParamsPropsVariant2,
} from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { tooltipThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { CellRendererParam } from '@cfra-nextgen-frontend/shared/src/components/types/fieldViewData';
import { TypographyStyle10 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { getValueByPath, getValuesByPath } from '@cfra-nextgen-frontend/shared/src/utils';
import { getCursorVariant1 } from '@cfra-nextgen-frontend/shared/src/utils/cursor';
import { getIcon } from '@cfra-nextgen-frontend/shared/src/utils/icons';
import { Box, Grid, ThemeProvider, Tooltip } from '@mui/material';
import { useState } from 'react';
import { researchLinkGetterParams } from 'utils/researchLinkGetter';
import { useLinkGetter } from 'hooks/useLinkGetter';
import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import { getAnalyticsActionDataForLink } from './utils';

type LinkRetrieverProps = {
    resultValue: unknown;
    cellRendererParam?: CellRendererParam;
    type: string;
    iconName?: string;
};

export function LinkRetriever({ resultValue, cellRendererParam, type, iconName }: LinkRetrieverProps) {
    // provide all analytics data to the child components
    return (
        <AnalyticsDataContextProvider
            cfraDataLocal={{
                actionData:
                    getAnalyticsActionDataForLink({
                        resultValue,
                        param: cellRendererParam,
                        analyticsActionTrackingMode: cellRendererParam?.item_data?.analytics_action_tracking_mode,
                        initialActionProps: {},
                    }) || {},
            }}>
            <LinkRetrieverInner {...{ resultValue, cellRendererParam, type, iconName }} />
        </AnalyticsDataContextProvider>
    );
}

export function LinkRetrieverInner({ resultValue, cellRendererParam, type, iconName }: LinkRetrieverProps) {
    const { setRequestParamsProps, isLoading, isError } = useLinkGetter(...researchLinkGetterParams);
    const [modalOpen, setModalOpen] = useState(false);

    const itemData = cellRendererParam?.item_data;

    if (Array.isArray(resultValue) && resultValue.length !== 1) {
        throw new Error(
            'The result value for the LinkRetriever component received an array with more than one element.',
        );
    }

    const source = Array.isArray(resultValue) ? resultValue[0] : resultValue;

    switch (type) {
        case 'open_link':
            if (!itemData) {
                throw new Error('The itemData prop is required for the open_link type of the LinkRetriever component.');
            }

            let hasLock = false;
            const columnText = getValueByPath(source, itemData?.field) || cellRendererParam?.no_results_symbol;

            if ('lock_field' in itemData) {
                if (!('no_results_symbol' in cellRendererParam && columnText === cellRendererParam.no_results_symbol)) {
                    hasLock = !getValueByPath(source, itemData?.lock_field);
                }
            }

            return (
                <ThemeProvider theme={tooltipThemeV2}>
                    <Tooltip title={isError ? itemData?.errorTooltipText : itemData?.tooltipText} placement={'top'}>
                        <Box
                            sx={{
                                display: 'flex',
                                ...(hasLock ? { alignItems: 'center' } : {}),
                            }}>
                            {hasLock && (
                                <LockIconComponent
                                    modalOpen={modalOpen}
                                    setModalOpen={setModalOpen}
                                    ModalComponent={LockModal}
                                />
                            )}
                            <TypographyStyle10
                                sx={{
                                    '&:hover': {
                                        color: '#5A9CD1',
                                        cursor: getCursorVariant1(isLoading, isError),
                                    },
                                    lineHeight: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                onClick={() => {
                                    if (hasLock) {
                                        if (!modalOpen) setModalOpen(true);
                                    } else {
                                        setRequestParamsProps(
                                            getRequestParamsPropsVariant1(
                                                getValueByPath(source, itemData?.item_id_field),
                                            ),
                                        );
                                    }
                                }}>
                                {columnText}
                            </TypographyStyle10>
                        </Box>
                    </Tooltip>
                </ThemeProvider>
            );
        case 'svg_icon':
            const Icon = getIcon(iconName);
            const filterNamesToValues = itemData?.item_names_to_paths
                ?.map(({ item_name, item_path, item_value }) => ({
                    [item_name]: { values: item_value || getValuesByPath(source, item_path) },
                }))
                .reduce((acc, item) => ({ ...acc, ...item }), {});

            return (
                <ThemeProvider theme={tooltipThemeV2}>
                    <Tooltip title={isError ? itemData?.errorTooltipText : itemData?.tooltipText} placement={'top'}>
                        <Grid
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: getCursorVariant1(isLoading, isError),
                            }}>
                            <IconWithLoading
                                icon={Icon}
                                iconConfiguration={
                                    isError ? iconConfigurationVariant1.error : iconConfigurationVariant1.normal
                                }
                                onClick={() =>
                                    setRequestParamsProps(
                                        getRequestParamsPropsVariant2(filterNamesToValues, itemData?.request_params),
                                    )
                                }
                                isLoading={isLoading}
                                iconSx={{
                                    cursor: 'pointer',
                                }}
                            />
                        </Grid>
                    </Tooltip>
                </ThemeProvider>
            );
        default:
            throw new Error(`The type ${type} is not supported by the LinkRetriever component.`);
    }
}
